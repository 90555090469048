// ---------------------------------------------------------===
// Variables
// ---------------------------------------------------------===

// Colors
$gray: #999;
$white: #fff;
$flamingo: #ef4634;
$nile-blue: #173856;
$firefly: #0a1927;
$oslo-gray: #848c93;

$font-path: "../../fonts";
$icomoon-font-path: "#{$font-path}/icomoon";
$image-path: "../../images";

$bitter:  'bitter', 'Times New Roman', 'Times', sans-serif;
$raleway: 'raleway', 'Arial', 'Helvetica', sans-serif;
$base-font-sans-serif: $raleway;
$base-font-serif: $bitter;

// Body
$base-text-color: $firefly !default;
$base-background-color: $white !default;
$font-size-base: 14px !default;
$line-height-base: 1.714 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $nile-blue;

// Buttons 
$btn-color: $white !default;
$btn-background: $firefly !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $firefly !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 54px !default;
$h2-font-size: 30px !default;
$h3-font-size: 28px !default;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: $base-font-serif;
$headings-color: inherit !default;


$animation-speed: 0.3s;