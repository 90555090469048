// ---------------------------------------------------------===
// Common styles
// ---------------------------------------------------------===

.visual {
	padding: 150px 0 30px;
	color: $white;
	font-size: 18px;
	font-weight: 500;
	text-align: center;
	background-position: 50% 0;

	@include media('>=tablet') {
		@include v-align(470px);
	}

	@include media('>=desktop') {
		&:after {
			min-height: 567px;
		}
	}

	@include media('<tablet') {
		padding: 120px 0 30px;
		font-size: 15px;
	}

	&-content {
		max-width: 820px;
		margin: 0 auto;
	}

	h1 {
		line-height: 1.2;
		margin: 0 0 14px;
		letter-spacing: -1px;

		@include media('<tablet') {
			line-height: 1;
		}
	}

	.btn {
		margin-top: 23px;
	}

	.link-to-down {
		padding-top: 67px;

		@include media('<tablet') {
			padding-top: 55px;
		}
	}

	.icon-chevron-down {
		font-size: 14px;
		color: $white;
		opacity: 0.5;
		display: inline-block;
		vertical-align: top;

		&:hover {
			animation: up-down 0.8s ease infinite;
		}
	}

	&.visual-sm {
		background-position: 50% 0;
		padding: 167px 0 96px;

		&:after {
			display: none;
		}
	}
}

.cta-section {
	background: $nile-blue;
	padding: 50px 0 54px;
	text-align: center;
	color: rgba($white, 0.78);
	position: relative;
	overflow: hidden;

	@include media('<tablet') {
		padding: 40px 0 45px;
	}

	h2 {
		color: $white;
		margin: 0 0 4px;

		@include media('<tablet') {
			margin: 0 0 10px;
		}
	}

	p {
		margin: 0 0 19px;
	}

	.container {
		max-width: 630px;
	}

	&.bg-orange {
		background: $flamingo;
	}

	&.blue {

		&:after {
			content: "";
			background: url('#{$image-path}/pattern04.png') no-repeat;
			width: 552px;
			height: 309px;
			position: absolute;
			left: -149px;
			margin-top: -6px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;

			@include media('<desktop') {
				left: -350px;
			}

			@include media('<tablet') {
				left: -450px;
			}
		}

		&:before {
			content: "";
			background: url('#{$image-path}/pattern05.png') no-repeat;
			width: 403px;
			height: 353px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -17px;
			z-index: 1;
			margin-top: 6px;

			@include media('<desktop') {
				right: -160px;
			}

			@include media('<tablet') {
				right: -270px;
			}
		}
	}
}

.blog-section {
	background: #f3f7fa;
	padding: 82px 0 42px;
	position: relative;
	overflow: hidden;

	@include media('>=tablet', '<desktop') {
		margin: 0 -20px;
	}

	@include media('<widescreen') {
		padding: 70px 0;
	}

	@include media('<tablet') {
		padding: 45px 0 1px;
	}

	&:after {
		content: "";
		background: url('#{$image-path}/pattern01.png') no-repeat;
		width: 552px;
		height: 309px;
		position: absolute;
		left: -203px;
		bottom: -2px;
		z-index: 1;

		@include media('<desktop') {
			left: -300px;
		}
	}

	&:before {
		content: "";
		background: url('#{$image-path}/pattern07.png') no-repeat;
		width: 503px;
		height: 309px;
		position: absolute;
		right: 0;
		top: 46px;
		z-index: 1;

		@include media('<desktop') {
			right: -100px;
		}

		@include media('<tablet') {
			right: -300px;
		}
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		max-width: 1210px;

		&:after {
			display: none;
		}
	}

	.blog {
		width: 50%;

		@include media('>=desktop') {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		@include media('>=tablet', '<desktop') {
			padding: 0 20px;
		}

		@include media('<tablet') {
			margin: 0 0 44px;
			width: 100%;
		}
	}

	.article {
		width: 45%;
		padding-right: 13px;
		line-height: 1.714;
		color: rgba($firefly, 0.6);

		@include media('>=desktop') {
			order: 1;
		}

		@include media('<widescreen') {
			width: 50%;
		}

		@include media('<desktop') {
			width: 100%;
			padding-right: 0;
		}

		h2 {
			line-height: 1;
			letter-spacing: -0.29px;
			line-height: 1.267;
			color: $firefly;
			margin: 0 0 20px;

			@include media('<tablet') {
				margin: 0 0 10px;
			}
		}

		p {
			margin: 0 0 25px;

			@include media('<tablet') {
				margin: 0 0 20px;
			}
		}
	}

	.img-hold {
		width: 55%;

		@include media('>=desktop') {
			order: 2;
		}

		@include media('<widescreen') {
			width: 50%;
		}

		@include media('<desktop') {
			width: 100%;
			margin: 0 0 15px;
		}

		img {
			@include media('>=tablet', '<desktop') {
				height: 210px;
				width: auto;
				max-width: none;
			}
		}

		&.offset {
			.img {
				@include media('>=desktop') {
					margin: 0 -50px 0 0;
				}

				@include media('>=widescreen') {
					margin: -10px -93px 0 24px;
				}
			}
		}
	}
}

.news-section {
	padding: 101px 0 90px;
	color: rgba($firefly, 0.6);
	position: relative;
	overflow: hidden;

	@include media('<tablet') {
		padding: 45px 0;
	}

	&:after {
		content: "";
		background: url('#{$image-path}/pattern01.png') no-repeat;
		width: 552px;
		height: 309px;
		position: absolute;
		left: -156px;
		top: 109px;
		z-index: 1;

		@include media('<desktop') {
			left: -350px;
		}
	}

	&:before {
		content: "";
		background: url('#{$image-path}/pattern06.png') no-repeat;
		width: 602px;
		height: 352px;
		position: absolute;
		bottom: -70px;
		right: -17px;
		z-index: 1;
		margin-top: 6px;

		@include media('<desktop') {
			right: -250px;
		}

		@include media('<tablet') {
			right: -300px;
		}
	}

	.news-post{
		margin: 0 0 38px;
		overflow: hidden;

		@include media('<tablet') {
			margin: 0 0 20px;
		}

		time {
			display: block;
			font-weight: 600;
			color: rgba($firefly, 0.5);
			font-size: 16px;
			margin: 0 0 10px;

			@include media('>=tablet') {
				width: 205px;
				float: left;
				padding-right: 20px;
				margin: 0;
			}
		}

		.news-desc {
			overflow: hidden;
		}

		p {

			a {
				color: rgba($firefly, 0.6);
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	h2 {
		color: $firefly;
		font-size: 32px;
		letter-spacing: -0.5px;
		margin: 0 0 15px;
		line-height: 1.188;

		@include media('<tablet') {
			font-size: 22px;
		}

		a {
			color: $firefly;

			&:hover {
				color: $flamingo;
			}
		}
	}

	.sub-title {
		color: rgba($firefly, 0.6);
		font-size: 16px;
		font-weight: 600;
		display: block;
		margin: 0 0 10px;
	}

	.btn-hold {
		text-align: center;
	}
}