// ---------------------------------------------------------===
// Location page styles
// ---------------------------------------------------------===

// location info block

.location-info-block {
    @extend %clearfix;
    position: relative;

    .location-info {
        float: left;
        width: 39.93%;
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.5px;

        @include media('<desktop') {
            float: none;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        @include media('<tablet') {
            font-size: 14px;
        }

        @include media('<lg-phone') {
            display: block;
        }

        &:after {

            @include media('<desktop') {
                content: "";
                display: block;
                clear: both;
            }
        }

        .top-content {
            @extend %clearfix;

            @include media('<desktop') {
                float: left;
                width: 50%;
            }

            @include media('<lg-phone') {
                float: none;
                width: 100%;
            }

            .holder {
                padding: 70px 0 60px;
                width: 426px;
                float: right;

                @include media('<widescreen') {
                    max-width: 426px;
                    width: auto;
                    padding: 50px 50px 50px 20px;
                }

                @include media('<desktop') {
                    float: none;
                    max-width: none;
                    padding: 30px;
                }
            }

            .frame {
                max-width: 297px;

                @include media('<desktop') {
                    max-width: none;
                }
            }

            .description {
                margin-bottom: 43px;

                @include media('<desktop') {
                    margin-bottom: 20px;
                }
            }
        }

        .address-info {

            .title {
                display: block;
                font-size: 24px;
                line-height: 1.17;
                font-family: $bitter;
                margin-bottom: 17px;
                letter-spacing: -0.8px;
            }

            address {
                font-style: normal;
            }
        }
    }

    .img-wrap {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 60.07%;

        @include media('<desktop') {
            position: relative;
            width: 100%;
            padding-top: 50%;
        }
    }

    .office-wrap {
        @extend %clearfix;
        position: relative;
        padding-top: 50.84%;
        overflow: hidden;

        @include media('<widescreen') {
            padding-top: 70%;
        }

        @include media('<desktop') {
            width: 50%;
            background-position: 25% 50% !important;
        }

        @include media('<lg-phone') {
            padding-top: 50%;
            width: 100%;
        }
    }
}

// features block
.features-block {
    position: relative;
    padding: 100px 0 44px;
    overflow: hidden;

    @include media('<tablet') {
        padding: 50px 0 20px;
    }

    &:after,
    &:before {
        content: "";
        background: url('#{$image-path}/pattern01.png') no-repeat;
        width: 552px;
        height: 309px;
        position: absolute;
        left: 0;
        top: 70px;
        z-index: 1;
    }

    &:after {
        left: auto;
        top: auto;
        right: 0;
        bottom: 50px;
        background: url('#{$image-path}/pattern02.png') no-repeat;
        width: 403px;
        height: 353px;
    }

    > .container {
        position: relative;
        z-index: 2;
    }

    .three-cols {
        margin: 0 -28px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include media('<widescreen') {
            margin: 0 -15px;
        }

        .col {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            padding: 0 28px 56px;
            display: flex;
            flex-wrap: wrap;

            @include media('<widescreen') {
                padding: 0 15px 30px;
            }

            @include media('<desktop') {
                width: 50%;
            }

            @include media('<lg-phone') {
                width: 100%;
            }
        }
    }

    .col-holder {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        background: $white;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 21px 38px rgba(0, 0, 0, 0.06);

        .img-wrap {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .content-wrap {
        padding: 38px 31px 26px 35px;
        letter-spacing: 0.5px;

        @include media('<lg-phone') {
            padding: 30px 20px;
        }

        h3 {
            font-size: 28px;
            line-height: 1.18;
            padding-right: 36px;
            margin-bottom: 16px;
            letter-spacing: -0.8px;

            @include media('<tablet') {
                font-size: 20px;
                line-height: 1.3;
                padding-right: 0;
            }
        }
    }
}

// quote block
.quote-block {
    @extend %clearfix;
    background: #f3f7fa;
    position: relative;
    width: 100%;
    display: table;
    height: 100%;
    overflow: hidden;

    @include media('<lg-phone') {
        display: block;
    }

    .img-holder {
        width: 411px;
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        position: relative;
        z-index: 2;

        @include media('<widescreen') {
            width: 30%;
        }

        @include media('<lg-phone') {
            display: block;
            width: 100%;
            padding: 40px 20px 0;
        }

        .img-frame {
            @include media('<lg-phone') {
                max-width: 200px;
                margin: 0 auto 30px;
            }

            img {
                width: 100%;
            }
        }
    }

    .text-container {
        width: calc(100% - 411px);
        padding: 40px 0;
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        position: relative;
        z-index: 1;

        @include media('<lg-phone') {
            position: static;
            display: block;
        }

        &:after {
            content: "";
            background: url('#{$image-path}/pattern01.png') no-repeat;
            width: 552px;
            height: 309px;
            position: absolute;
            left: -287px;
            bottom: -48px;
            z-index: 1;

            @include media('<desktop') {
                left: -350px;
            }

            @include media('<tablet') {
                left: -350px;
            }
        }

        &:before {
            content: "";
            background: url('#{$image-path}/pattern02.png') no-repeat;
            width: 403px;
            height: 353px;
            position: absolute;
            right: -190px;
            top: -117px;
            z-index: 1;

            @include media('<desktop') {
                right: -250px;
            }
        }

        @include media('<1600px') {
            max-width: 940px;
            padding: 0 40px 0 69px;
        }

        @include media('<widescreen') {
            width: 70%;
            padding: 0 40px 0 0;
        }

        @include media('<desktop') {
            padding: 20px 0;
        }

        @include media('<lg-phone') {
            float: none;
            width: 100%;
            padding: 0 0 50px;
        }
    }

    .quote-area {
        @extend %clearfix;
        padding: 40px 20px 40px 100px;
        max-width: 1126px;

        @include media('<1760px') {
            padding-left: 20px;
        }

        @include media('<widescreen') {
            padding-left: 50px;
        }

        @include media('<desktop') {
            padding: 20px;
        }

        @include media('<tablet') {
            padding-top: 0;
            padding-bottom: 0;
        }

        blockquote {
            position: relative;
            letter-spacing: 0.8px;

            q {
                font: italic 24px/1.75 $bitter;
                display: block;
                color: rgba(#0a1927, 0.7);
                position: relative;
                padding: 36px 0 27px 98px;

                @include media('<desktop') {
                    font-size: 18px;
                    padding: 20px 45px 20px;
                }

                @include media('<tablet') {
                    padding: 6px 0 16px 45px;
                    font-size: 16px;
                }

                @include media('<lg-phone') {
                    text-align: center;
                    padding: 20px;
                }

                &:before,
                &:after {
                    content: "\e903";
                    position: absolute;
                    left: 0;
                    top: 0;
                    font: 50px/1 "icomoon";
                    color: #ef4634;

                    @include media('<desktop') {
                        font-size: 28px;
                    }

                    @include media('<tablet') {
                        font-size: 20px;
                    }

                    @include media('<lg-phone') {
                        left: -14px;
                        top: 5px;
                    }
                }

                &:after {
                    content: "\e904";
                    left: auto;
                    top: auto;
                    right: 10px;
                    bottom: -20px;

                    @include media('<lg-phone') {
                        bottom: 20px;
                        right: -14px;
                    }
                }
            }

            cite {
                display: block;
                text-align: right;
                font: 24px/1.2 $bitter;
                padding-right: 130px;
                letter-spacing: 0;

                @include media('<desktop') {
                    font-size: 18px;
                    padding-right: 90px;
                }

                @include media('<lg-phone') {
                    text-align: center;
                    padding-right: 0;
                    margin-top: 10px;
                }
            }
        }

        .holder {
            max-width: 1044px;
            float: right;

            @include media('<lg-phone') {
                float: none;
            }
        }
    }
}




