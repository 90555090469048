// ---------------------------------------------------------===
// General styles
// ---------------------------------------------------------===

#wrapper {
	overflow: hidden;
}

.container {
	@extend %clearfix;
	max-width: 1184px;
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;
	position: relative;
	z-index: 10;
}

body {
	letter-spacing: 0.5px;
}

blockquote, q{
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
	quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}

.btn {
	display: inline-block;
	vertical-align: top;
	padding: 7px 20px;
	background: rgba(255, 255, 255, 0.4);
	border-radius: 100px;
	font-size: 14px;
	color: $white;
	font-weight: 600;
	letter-spacing: 1px;
	min-width: 127px;
	text-align: center;

	@include media('<tablet') {
		padding: 7px 15px;
		font-size: 12px;
	}

	&:hover {
		background: rgba(255, 255, 255, 0.6);
	}

	&-white-o {
		background: none;
		border: 1px solid rgba(255, 255, 255, 0.5);

		&:hover {
			background: rgba(255, 255, 255, 0.5);
		}
	}

	&-orange {
		background: $flamingo;

		&:hover {
			background: darken($flamingo, 10%);
		}
	}

	&-white {
		background: $white;
		color: $flamingo;

		&:hover {
			background: rgba($white, 0.8);
		}
	}

	&-orange-o {
		background: none;
		border: 1px solid $flamingo;
		color: $flamingo;

		&:hover {
			background: $flamingo;
			color: $white;
		}
	}

	&-lg {
		padding: 16px 40px;

		@include media('<tablet') {
			padding: 12px 25px;
		}
	}
}

.background {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@keyframes up-down {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(5px);
	}

	100% {
		transform: translateY(0);
	}
}