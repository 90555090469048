#index-page {
  .visual.background {
    padding-top: 60px;
    position: relative;
    &:before {
      position: absolute;
      top: 0; left: 0;
      z-index: -99;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(black,0.45);
    }
    video {
      position: absolute;
      top: 0; left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translate(-50%,0);
      background-size: cover;
      transition: 1s opacity;

      @include media('<desktop') {
        min-width: 130%;
      }
    }
    @include media('<widescreen') {
      padding: 0;
      min-width: 100%;
    }
    @include media('<desktop') {
      padding: 60px 0 30px;
    }
    @include media('<tablet') {
      padding: 100px 0 30px;
    }
  }
}