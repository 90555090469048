// ---------------------------------------------------------===
// Typography
// ---------------------------------------------------------===

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
    font-family: $headings-font-family;
    font-weight: bold;
    margin: 0 0 0.5em;
    color: $headings-color;
    font-weight: 400;
    letter-spacing: -0.8px;
}

h1, .h1 {
    font-size: $h1-font-size;

    @include media('<desktop') {
        font-size: 40px;
    }   

    @include media('<tablet') {
        font-size: 33px;
    }
}

h2, .h2 {
    font-size: $h2-font-size;

    @include media('<tablet') {
        font-size: 22px;
        line-height: 1.3;
    }
}

h3, .h3 {
    font-size: $h3-font-size;
}

h4, .h4 {
    font-size: $h4-font-size;
}

h5, .h5 {
    font-size: $h5-font-size;
}

h6, .h6 {
    font-size: $h6-font-size;
}

p {
    margin: 0 0 1em;
}

a {
    @include animate(border color background opacity);
    color: $base-link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
    }
}