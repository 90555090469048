// ---------------------------------------------------------===
// General resets
// ---------------------------------------------------------===

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

* {max-height: 1000000px;}

body {
	color: $base-text-color;
	background: $base-background-color;
	font: #{$font-size-base}/#{$line-height-base} $base-font-family;
	min-width: $base-min-width;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.resize-active * {transition-duration: 0s !important;}

// adaptive images
img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

// google map fix
.gm-style img {max-width: none}

// hide tel links on desktop
@media only screen and (min-width:1025px) {
    a[href^=tel],a[href^=skype], a[href^=sms] {
        cursor:default;
        pointer-events:none;
    }
}
