// ---------------------------------------------------------===
// Header styles
// ---------------------------------------------------------===

#header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
	padding: 44px 0;
	min-width: 320px;

	@include media('<tablet') {
		padding: 15px 0;
	}

	.container {
		@extend %clearfix;
	}

	.logo {
		float: left;
		padding-right: 10px;

		@include media('<tablet') {
			width: 120px;
		}
	}

	.btn {
		float: right;
	}
}