/* ---------------------------------------------------------
   Font face
   ---------------------------------------------------------*/

// icomoon

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?woareb');
  src:  url('#{$icomoon-font-path}/icomoon.eot?woareb#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?woareb') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?woareb') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?woareb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

//bitter
@font-face {
    font-family: 'bitter';
    src: url('#{$font-path}/bitter/bitter-italic-webfont.eot');
    src: url('#{$font-path}/bitter/bitter-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/bitter/bitter-italic-webfont.woff2') format('woff2'),
         url('#{$font-path}/bitter/bitter-italic-webfont.woff') format('woff'),
         url('#{$font-path}/bitter/bitter-italic-webfont.ttf') format('truetype'),
         url('#{$font-path}/bitter/bitter-italic-webfont.svg#bitteritalic') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'bitter';
    src: url('#{$font-path}/bitter/bitter-regular-webfont.eot');
    src: url('#{$font-path}/bitter/bitter-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/bitter/bitter-regular-webfont.woff2') format('woff2'),
         url('#{$font-path}/bitter/bitter-regular-webfont.woff') format('woff'),
         url('#{$font-path}/bitter/bitter-regular-webfont.ttf') format('truetype'),
         url('#{$font-path}/bitter/bitter-regular-webfont.svg#bitterregular') format('svg');
    font-weight: 400;
    font-style: normal;
}


//raleway
@font-face {
    font-family: 'raleway';
    src: url('#{$font-path}/raleway/raleway-semibold-webfont.eot');
    src: url('#{$font-path}/raleway/raleway-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/raleway/raleway-semibold-webfont.woff2') format('woff2'),
         url('#{$font-path}/raleway/raleway-semibold-webfont.woff') format('woff'),
         url('#{$font-path}/raleway/raleway-semibold-webfont.ttf') format('truetype'),
         url('#{$font-path}/raleway/raleway-semibold-webfont.svg#ralewaysemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'raleway';
    src: url('#{$font-path}/raleway/raleway-medium-webfont.eot');
    src: url('#{$font-path}/raleway/raleway-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/raleway/raleway-medium-webfont.woff2') format('woff2'),
         url('#{$font-path}/raleway/raleway-medium-webfont.woff') format('woff'),
         url('#{$font-path}/raleway/raleway-medium-webfont.ttf') format('truetype'),
         url('#{$font-path}/raleway/raleway-medium-webfont.svg#ralewaymedium') format('svg');
    font-weight: 500;
    font-style: normal;
}




@font-face {
    font-family: 'raleway';
    src: url('#{$font-path}/raleway/raleway-regular-webfont.eot');
    src: url('#{$font-path}/raleway/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/raleway/raleway-regular-webfont.woff2') format('woff2'),
         url('#{$font-path}/raleway/raleway-regular-webfont.woff') format('woff'),
         url('#{$font-path}/raleway/raleway-regular-webfont.ttf') format('truetype'),
         url('#{$font-path}/raleway/raleway-regular-webfont.svg#ralewayregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
