// ---------------------------------------------------------===
// Footer styles
// ---------------------------------------------------------===

#footer {
	padding: 30px 0;

	.container {
		max-width: none;

		@include media('<tablet') {
			padding: 0 15px;
		}
	}

	.copyright {
		color: rgba(#32212b, 0.5);
		font-size: 12px;
		letter-spacing: 0;
		display: block;
		text-align: center;

		@include media('>=desktop') {
			float: left;
			max-width: 31%;
			padding-top: 10px;
			text-align: left;
		}

		@include media('<tablet') {
			font-size: 11.5px;
		}

		img {
			margin-right: 21px;

			@include media('<widescreen') {
				margin-right: 10px;
			}
		}
	}

	.links-pannel {
		text-align: center;
		margin: 0 0 20px;

		@include media('>=tablet') {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			text-align: left;
			justify-content: center;
			margin: 0 -10px 35px 0;
		}

		@include media('>=desktop') {
			margin: 0 -10px 0 0;
			float: right;
			max-width: 69%;
			justify-content: flex-end;
		}
	}
}

.footer-link {
	@extend %listreset;
	padding-left: 10px;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 2.2px;

	@include media('<desktop') {
		padding-left: 0;
	}

	@include media('<tablet') {
		margin: 0 0 15px;
	}

	li {
		display: inline-block;
		vertical-align: middle;

		& + li {
			&:before {
				content: '|';
				margin: 0 16px 0 10px;
			}
		}

		a {
			color: $firefly;

			&:hover {
				opacity: 0.6;
			}
		}
	}
}

.btns {
	@extend %listreset;
	padding-left: 20px;

	@include media('<tablet') {
		padding-left: 0;
	}

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 0 5px;

		@include media('<tablet') {
			margin: 0 0 15px;
		}
	}
}

.social-networks {
	@extend %listreset;
	padding-left: 20px;

	@include media('<tablet') {
		padding-left: 0;
	}

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 0 10px;

		a {
			color: $firefly;

			&:hover {
				color: $flamingo;
			}

			> i {
				font-size: 15px;
			}
		}
	}
}